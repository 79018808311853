.pageViewBox {
  margin-top: 30px !important;
  margin: auto;
  padding: 0px 40px 10px 40px;
}

h2.intro {
  text-transform: uppercase !important;
  font-size: 1rem;
}

h3 {
  text-transform: uppercase !important;
  font-size: 0.95rem;
  color: var(--primary-color);
}
.fieldText {
  margin-top: 5px;
}

.signatureImageBox {
  border: 1px solid #a0a0a0;
  width: 80%;
  margin-top: 10px;
}

@media print {
  h2.intro,
  .pageViewBox {
    padding-left: 0px;
    padding-right: 0px;
  }
}