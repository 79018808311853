.pageBox {
  margin-top: 20px !important;
  margin: auto;
  box-shadow: 0px 1px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 25px;
}

h2.intro {
  text-transform: uppercase !important;
  font-size: 1rem;
}

h3 {
  text-transform: uppercase !important;
  font-size: 0.95rem;
  color: var(--primary-color);
}

.reviewAndSubmitButton {
  text-align: center;
  width: "100%";
  margin-bottom: "50px";
}

@media print {
  .printOnly {
    display: block;
  }

  .reviewAndSubmitButton {
    display: none;
  }

  .hideOnPrint {
    display: none;
  }
}


@media print {
  .pageBox {
    box-shadow: none !important;
    padding: 0 !important;
  }
}