.pageBox {
  margin-top: 20px !important;
  margin: auto;
  box-shadow: 0px 1px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 25px;
  padding: 40px;
}

h2.intro {
  text-transform: uppercase !important;
  font-size: 1rem;
}

h2.accordionTitle {
  text-transform: uppercase !important;
  font-size: 0.95rem;
  margin: 0px !important;
  padding: 0px !important;
}

@media print {
  .pageBox {
    box-shadow: none;
    padding: 0;
  }
}