.loader {
  position: fixed;
  z-index: 999;
  background-color: var(--white-color);
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0.7;
}
