.pageViewBox {
  margin-top: 20px !important;
  margin: auto;
}

h2.intro {
  text-transform: uppercase !important;
  font-size: 1rem;
  padding: 30px 40px 25px 40px;
  border-bottom: 1px solid #e1e1e1;
}

h3 {
  text-transform: uppercase !important;
  font-size: 0.95rem;
  color: var(--primary-color);
}

.pageViewBox ol {
  padding-left: 22px !important;
}

.pageViewBox li {
  margin-bottom: 10px !important;
}

.consentText {
  padding: 0px 40px 5px 35px;
  border-bottom: 1px solid #e1e1e1;
}

.consentForm {
  padding: 0px 15px 20px 35px;
}

.fieldText {
  margin-top: 5px;
}

.signatureImageBox {
  border: 1px solid #a0a0a0;
  width: 80%;
  margin-top: 10px;
}

@media print {
  .consentText {
    padding: 0;
  }

  h2.intro,
  .consentForm {
    padding-left: 0px;
    padding-right: 0px;
  }
}
