.pageTitleBox {
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.registerBox {
  width: 400px;
  margin: auto;
  margin-top: 50px;
  box-shadow: 0px 1px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 25px;
  padding: 50px;
}

form {
  margin-top: 10px;
}

.registerBox h1 {
  margin-top: 0px;
  margin-bottom: 0px;
}
