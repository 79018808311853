.unableToSign {
  width: 80%;
  margin-top: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background-color: #F5F5F5;
  border: 1px solid #A0A0A0;
}

.unableToSign p {
  margin: 0 !important;
  color: rgb(97, 97, 97);
}

.signatureImageBox {
  border: 1px solid #A0A0A0;
  width: 80%;
  margin-top: 10px;
}