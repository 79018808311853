
.pageTitleBox {
  text-align: center;
  padding-bottom: 5px;
}

.pageBox {
  width: 500px;
  margin: auto;
  margin-top: 100px;
  box-shadow: 0px 1px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 25px;
  padding: 50px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pageBoxBody {
  margin-top: 30px;
}

.pageBox h1 {
  margin-top: 0px;
  margin-bottom: 0px;
}