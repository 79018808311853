:root {
  --primary-color: #00558c;
  --white-color: #ffffff;
  --red-color: red;
  --dropzone-color: whitesmoke;
  --grey-color: grey;
  --error-color: #d32f2f;
  --border-bottom: #bdbdbd;
  --dark-grey-color: #1b1b1b;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.95rem;
  margin-top: 100px;
  margin-bottom: 20px;
}

H1 {
  font-size: 28px;
  text-transform: uppercase;
  color: var(--primary-color);
  font-weight: normal;
}

H2 {
  font-size: 1.1rem;
  color: var(--primary-color);
  font-weight: bold;
  text-transform: uppercase;
  margin: 0px;
}

.MuiTextField-root {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

.MuiFormControl-root {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

.MuiButton-contained {
  height: 45px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 20px !important;
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.MuiButton-outlined {
  height: 45px !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  border-radius: 20px !important;
  background-color: var(--white-color) !important;
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.MuiButton-contained.Mui-disabled {
  color: var(--white-color);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.2) !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiContainer-root {
  margin-top: 20px;
}

.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0px !important;
}

.MuiStepConnector-root {
  top: 15px !important;
}

.MuiButton-text {
  color: var(--white-color) !important;
}

.MuiStepIcon-root {
  color: var(--white-color) !important;
  border: 4px solid #aaaaaa;
  border-radius: 50px;
  font-weight: bold;
}

.MuiStepIcon-text {
  fill: var(--primary-color) !important;
  font-size: 0.8rem !important;
}

.MuiStepIcon-root.Mui-active {
  color: var(--primary-color) !important;
  border: 4px solid #aaaaaa;
  border-radius: 30px;
}

.MuiStepIcon-root.Mui-active text {
  fill: var(--white-color) !important;
}

.MuiStepLabel-label {
  text-align: center;
  margin-top: 8px !important;
}

.MuiStepLabel-labelContainer span {
  font-size: 0.95rem !important;
  font-weight: bold !important;
}

.MuiStepIcon-root.Mui-completed {
  color: var(--primary-color) !important;
}

.MuiStepIcon-root {
  font-size: 1.7rem !important;
}

.MuiStepConnector-line {
  border-top-width: 3px !important;
}

.MuiFormControl-root {
  margin-bottom: 5px !important;
}

.MuiOutlinedInput-input {
  height: 19px !important;
}

.MuiInputBase-inputMultiline {
  height: auto !important;
}

.MuiSelect-select {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.MuiSelect-root {
  margin-top: 10px !important;
}

.mandatoryStar {
  color: var(--red-color);
}

.formErrorMessage {
  color: var(--error-color);
  font-weight: 400;
  padding-left: 15px;
  font-size: 0.8rem;
}

.dropzone {
  border: 1px dashed var(--grey-color);
  text-align: center;
  border-radius: 10px;
  background: var(--dropzone-color);
  padding: 30px;
  margin-left: 8px;
  margin-right: 8px;
}

.dropzoneRequired {
  border: 1px dashed var(--red-color);
  color: var(--red-color);
  text-align: center;
  border-radius: 10px;
  background: var(--dropzone-color);
  padding: 30px;
  margin-left: 8px;
  margin-right: 8px;
}

.dropzone:hover {
  border: 1px dashed var(--primary-color);
}

.MuiStepLabel-label {
  font-size: 16px !important;
}

.MuiIconButton-sizeMedium {
  padding: 5px !important;
}

.MuiDataGrid-cell {
  cursor: pointer !important;
}

.MuiAvatar-circular {
  background: var(--primary-color) !important;
  color: var(--white-color) !important;
  font-weight: bold;
}

.form-field {
  margin-top: 10px;
}

header {
  z-index: 20000;
}

label {
  font-weight: bold;
}

.redStar {
  color: red;
}

input.Mui-disabled {
  color: black !important;
  -webkit-text-fill-color: black !important;
}

.MuiCheckbox-colorPrimary {
  color: var(--accepted-green-color) !important;
}

.printOnly {
  display: none;
}

.introSectionPrintMode {
  padding-top: 15px;
  padding-bottom: 15px;
}

@media print {
  .printOnly {
    display: block;
  }

  .pagebreak {
    page-break-before: always;
  }
}
