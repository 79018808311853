.bigButton {
  margin: auto;
  width: 162px;
  cursor: pointer;
  margin-bottom: 30px;
}

.bigButtonIcon {
  width: 110px;
  height: 70px;
  margin: auto;
  box-shadow: 0px 1px 10px 0 rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.bigButtonIcon:active {
  box-shadow: 0px 1px 5px 0 rgb(0 85 140);
}

.bigButtonIcon img {
  width: 60%;
}

.buttonLabel {
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
  width: 100%;
}
