.appHeader {
  height: 75px;
  width: 100%;
}

.menuContainer {
  margin-top: 15px;
  float: left;
  margin-left: 10px;
}

.iaviLogoContainer {
  text-align: center;
}

.iaviLogo {
  width: 165px;
  margin-top: 6px !important;
  margin: auto;
}

.projectTitle {
  color: var(--white-color);
  font-size: 19px;
  font-weight: bold;
  float: right;
  padding-right: 20px;
  height: 30px;
  margin-top: 20px;
  padding-top: 11px;
}

.userAvatar {
  float: right;
  background-color: var(--white-color);
  border-radius: 50px;
  color: var(--primary-color);
  height: 26px;
  width: 26px;
  font-size: 22px;
  padding: 8px;
  margin-top: 20px;
  margin-right: 20px;
}

.profileButton {
  float: right;
  right: 0px;
  left: -10px;
}

.langButton {
  float: right;
  right: 0px;
  left: -10px;
  top: -8px;
}

.profileAvatar {
  background-color: var(--white-color);
  color: var(--primary-color);
  padding: 3px;
}

.profileAvatarContainer {
  float: right;
  margin-top: 12px;
  margin-right: 5px;
  padding-left: 7px;
}

.showOnlineStatus {
  float: right;
  margin-top: 22px;
  margin-right: 5px;
  padding-left: 7px;
}

h1 {
  margin-top: 15px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.participantId {
  color: var(--primary-color);
  font-weight: bold;
  margin-top: 10px;
  font-size: 16px;
}

@media print {
  .menuContainer,
  .profileAvatarContainer {
    display: none !important;
  }
}
