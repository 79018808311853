.appTitle {
  margin-top: 120px;
  font-size: 25px;
}

.mainMenu {
  display: flex;
  align-items: center;
  margin-top: 100px;
}
